import React, { useState, useEffect } from 'react';
import NextHead from 'next/head';
import Grid from '@mui/material/Grid2';
import CommonHeaderText from '../components/packages/common-header-text';
import HBImage from '../components/packages/hbimage';
import SEOHeaderComponent from '../components/Pages/SEOHeaderComponent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Link from '@mui/material/Link';
import { useRouter } from 'next/router';
const NotFound = () => {
  const router = useRouter();
  const linkData = router.asPath;
  const [refLink, setrefLink] = useState('/search');
  useEffect(() => {
    linkData?.includes('rent')
      ? setrefLink('/rent/search')
      : setrefLink('/search');
  }, []);
  return (<>
    <NextHead>
      <meta name="robots" content="noindex" />
    </NextHead>
    <SEOHeaderComponent meta_data={{ title: 'Page not found' }} />
    <section className="not-section">
      <Grid container className="container">
        <Grid
          style={{
            margin: '0px auto',
          }}
          size={{
            sm: 12,
            md: 7,
            lg: 7
          }}>
          <div className="notfound">
            <HBImage
              width="500px"
              height="216px"
              src="/static/images/notfound.png"
              alt="notfound"
              title="Not Found"
            />
          </div>
          <div className="notfound-text">
            <CommonHeaderText
              text="The page you are searching for does not exist or under construction"
              variant="h5"
              style={{
                color: '#5A5A5A',
                fontSize: '15px',
                fontFamily: 'Open Sans, sans-serif!important',
                fontWeight: '400',
                marginBottom: '20px',
                textAlign: 'center',
              }}
            />
            <CommonHeaderText
              text="Watch for other options"
              variant="h5"
              style={{
                color: '#171717',
                fontSize: '17px',
                fontFamily: 'Open Sans, sans-serif!important',
                fontWeight: '700',
                marginBottom: '20px',
                textAlign: 'center',
              }}
            />
          </div>
          <div className="notfound-hr"></div>
          <div>
            <Link className="notfound-back" href={refLink}>
              <ArrowBackIcon className="back-arrow" />
              <CommonHeaderText
                text="Take me back to search page"
                variant="h5"
                style={{
                  color: '#5A5A5A',
                  fontSize: '14px',
                  fontFamily: 'Open Sans, sans-serif!important',
                  fontWeight: '400',
                  textAlign: 'center',
                }}
              />
            </Link>
          </div>
        </Grid>
      </Grid>
    </section>
  </>);
};

export default NotFound;
